import { ExpandCollapse, Link, Typography } from '@components/index';
import React from 'react';
import * as styles from "./FAQ.module.scss";

const questions: { title: string, description: JSX.Element }[] = [
    {
        title: "Are there discounts for academia and nonprofits?",
        description: <Typography color="white">Yes! We offer discounted pricing for academic labs and institutions. Learn more in each respective tab at the top or <Link className={styles.faqLink} link="CONTACT_US">contact us</Link> for more information.</Typography>
    },
    {
        title: "Does Genemod offer monthly billing options?",
        description: <Typography color="white">All Genemod plans currently require annual contract commitments.</Typography>
    },
    {
        title: "Are there multi-year discounts for paid plans?",
        description: <Typography color="white">Discounts are available depending on the length of the contract and whether the team is new to a paid plan. <Link className={styles.faqLink} link="CONTACT_US">Contact us</Link> to learn more.</Typography>
    },
    {
        title: "Is there startup pricing?",
        description: <Typography color="white">Our Team plan for Industry is geared towards startups and offers our most popular features. <Link className={styles.faqLink} link="DEMO">Contact us for a demo</Link> to see how we can help establish a strong digital foundation for your startup.</Typography>
    },
    {
        title: "How is my data backed up?",
        description: <Typography color="white">Genemod utilizes Amazon Web Services (AWS) for hosting. AWS is a leader in infrastructure security, and maintains multiple security and compliance certifications including ISO 27001, SOC 1, and SOC 2. Normally, a minimum of 30 days of data will be backed up and retained. Depending on the customer’s requirements, we can adjust data retention policies as needed.</Typography>
    },
    {
        title: "Can we interface to our ERP, financial systems, and other external applications?",
        description: <Typography color="white">Third-party software interfacing is accomplished in the same way as for on-site deployments. <Link className={styles.faqLink} link="DEMO">Contact us for a demo</Link> to see how we can help establish a strong digital foundation for your team.</Typography>
    },
    {
        title: "What are the benefits of a solution like Genemod?",
        description: (
            <div>
                <Typography color="white">The implementation of a modern R&D informatics platform like Genemod can support teams to:</Typography>
                <ul className={styles.list}>
                    <li className={styles.listItem}><Typography color="white">Increase efficiency: move to a paperless lab, improve resources management, and improve access to quality data across the organization</Typography></li>
                    <li className={styles.listItem}> <Typography color="white">Reduce errors: integrate with hardware & instruments, automation of processes to avoid manual data entry</Typography></li>
                    <li className={styles.listItem}><Typography color="white">Centralize & retain data: data is always readily available and searchable</Typography></li>
                    <li className={styles.listItem}><Typography color="white">Ensure regulatory compliance: 21 CFR Part 11 compliance and other regulatory guidelines help improve data integrity</Typography></li>
                </ul>
                <Typography color="white">To learn more about our package options and pricing, contact our sales team at <Link className={styles.faqLink} to={"mailto:genemod@genemod.net"} target="_blank">genemod@genemod.net</Link>.</Typography>
            </div>
        )
    },
];

const FAQ = () => {
    return (
        <div className={styles.faq}>
            {
                questions.map(({ title, description }, index) => (
                    <ExpandCollapse fullWidth key={index} title={<Typography variant='SUBTITLE' color='white'>{title}</Typography>} caret="LIGHT" icon='plus'>
                        <div style={{ marginTop: 30, marginRight: 80 }}>{description}</div>
                    </ExpandCollapse>
                ))
            }
        </div>
    )
}

export default FAQ